<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="drawer-bar auto">
          <div class="fl">
            <span class="font16"><b>系统准入策略</b></span>
          </div>
        </div>
        <div class="department-content auto" id="no-margin-form">
          <div class="box auto">
            <!-- <div class="box-title auto">
              <div class="fl">密码校验管理</div>
            </div> -->
            <div class="box-con auto m-t-20">
              <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="{span: 5}"
                :wrapper-col="{span: 14}"
                labelAlign="left"
                style="max-width: 800px;"
              >
                <a-form-model-item label="准入IP正则类型" prop="access_policy_api_type">
                  <a-radio-group v-model="form.access_policy_api_type" style="margin-left:-34px;">
                    <!-- <a-radio :value="item.value" v-for="(index, item) in ruleOptions" :key="index">{{item.label}}</a-radio> -->
                    <a-radio value="A01">IP枚举（半角逗号分隔）</a-radio>
                    <a-radio value="A02">IP正则表达式</a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="准入IP正则表达式" prop="access_policy_api_regex">
                  <a-input type="textarea" v-model="form.access_policy_api_regex" size="small" style="margin-left:-34px;"></a-input>
                </a-form-model-item>
                <a-form-model-item label="">
                  <a-button type="primary" size="small" style="margin-top:15px;" @click="submitPasswordverify">保存</a-button>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
        </div>
      </a-spin>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      loading: false,
      ruleOptions: [
        { label: 'IP枚举（半角逗号分隔）', value: 'A01' },
        { label: 'IP正则表达式', value: 'A02' }
      ],
      form: {
        access_policy_api_type: 'A01',
        access_policy_api_regex: ''
      },
      rules: {
        // access_policy_api_regex: [
        //   { required: true, message: '请输入IP正则表达式', trigger: 'blur' }
        // ]
      }
    }
  },
  methods: {
    async getPasswordverify () {
      this.loading = true
      let res = await System.getAccesspolicy()
      if (!res['code']) {
        if (res['data']) {
          this.form = {
            access_policy_api_type: res['data']['access_policy_api_type'],
            access_policy_api_regex: res['data']['access_policy_api_regex']
          }
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
      let res2 = await System.getAccountsafety()
      this.loading = false
      if (!res2['code']) {
        this.form2 = {
          password_modify_cycle: res2['data']['password_modify_cycle'],
          password_forcereset_cycle: res2['data']['password_forcereset_cycle'],
          account_lock_unuse: res2['data']['account_lock_unuse']
        }
      } else {
        // this.$message.error(res2['description'])
        this.$message.error(this.errMsg[res2['code']])
      }
    },
    async submitPasswordverify () {
      let form = {
        access_policy_api_type: this.form['access_policy_api_type'],
        access_policy_api_regex: this.form['access_policy_api_regex']
      }
      this.loading = true
      let res = await System.putAccesspolicy(form)
      this.loading = false
      if (!res['code']) {
        this.$message.success('保存成功！')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    this.getPasswordverify()
  },
  mounted () {
  }
};
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 20px;
}

.box {
}
.box-title .fl {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  width: 100%;
}
.box-con {
  border-bottom: 1px solid rgba(0,0,0,0.06);
  width: 100%;
  padding-bottom: 20px;
}
.box2 {
  margin-top: 20px;
}
.no-bor {
  border: none;
}

</style>
